#questioncontainer{
    width: 50%;
    display: grid;
    place-items: center stretch;
    padding: 20px;
}

.question{
    background-color: lightgreen;
    height: 100px;
    margin: 1px;
    border-radius: 25px;
    font-size: xxx-large;
    }
.answerbox{
    /*border: solid coral 3px;*/
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.option{
    background-color: lightblue;
    width: 45%;
    height: 50px;
    margin: 2px;
    border-radius: 25px;
}

.option:hover{
    background-color: cornflowerblue;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

span {
    display:block;
    overflow:hidden;
    width:100%;
    height:100%;
    border-radius: 25px;
}

input[type="checkbox"]:checked + span {
    font-weight: bold;
    color: white;
    background-color: deepskyblue;
}

input[type="checkbox"]  {
   display: none;
}

button{
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  color: #39739d;
  cursor: pointer;
  line-height: 1.15385;
  margin: 20px;
  padding: 8px .8em;
  text-decoration: none;
  white-space: nowrap;
}

button:hover,
button:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

button:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

button:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}

/* Message Popup Styles */
.message-popup {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    width: 25%;
    height: 350px;
    background-color: cyan;
    text-align: center;
    border-radius: 15px;
    font-size: larger;
    line-height: 30px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.message-popup.hidden {
  display: none;
}
