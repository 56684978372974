#App {
  /*border: solid green 5px;*/
  background-color: darkslategrey;
  text-align: center;
  display: grid;
  place-items: center stretch;
}

#main {
  /*border: solid chocolate 5px;*/
  background-color: white;
  padding-bottom: 50px;
  display: grid;
  place-items: center;
}
nav a {
  display: inline-block;
  float: left;
  background-color: lightsteelblue;
  color: white;
  text-decoration: none;
  padding: 10px;
  margin-top: 5px;
  min-width: 75px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

nav a:hover {
  background-color: lightgray;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

}